import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';



import 'style-loader!angular2-toaster/toaster.css';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';

const httpOptions = {
  headers: new HttpHeaders({
  'Content-Type':  'application/json',
  Authorization: 'Your-Api-Key'
})
};

@Injectable({
  providedIn: 'root',
})
export class ServicioService {
  // API_Uri = 'http://localhost/angular/interagro/API_REST';
  // API_Uri = "http://localhost:8888/pem-app/pem-cms/API_REST_ADMIN";
  // API_Uri = "https://apiclan.000webhostapp.com/API_REST_ADM";
  
  // API_Uri = 'https://empresa.tupasaje.bo/API_REST_ADMIN'; 
  // API_Uri = 'https://pem.clanbolivia.com/API_REST_ADMIN'; 

  API_Uri = 'https://clanbolivia.online/pem/API_REST_PEM'; 

  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  sesion = [];
  usuario: any;

  constructor(
    private http: HttpClient,
    private toastrService: NbToastrService,
  ) { }
  setUsuario(obj) {
    this.usuario = obj;
  }
  getUsuario() {
    return this.usuario;
  }
  guardarSesion(sesion) {
    this.sesion = sesion;
    sessionStorage.setItem('currentUser', JSON.stringify(sesion));
    // localStorage.clear();
  }
  recuperarSesion() {
    console.log(1);
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }
////////////////////////////////////////////////////////
// WS
// IA00ADM - POST - LOGIN DE USUARIO
getLoginUsuario(user: any, password: any) {
  return this.http.get(`${this.API_Uri}/getLoginUsuario/${user}/${password}`);
}

// IA01ADM - GET - OBTENER LISTA DE USUARIOS
getAllUsuario(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllUsuario/${idtoken}/${token}`);
}

// IA02ADM - POST - INSERTAR USUARIO
postInsertarUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarUsuario` , obj);
}

// IA03ADM - POST - MODIFICAR USUARIO
postModificarUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarUsuario` , obj);
}

// IA04ADM - POST - ELIMINAR USUARIO
postEliminarUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarUsuario` , obj);
}

// IA05ADM - GET - OBTENER LISTA DE TIPOS DE USUARIO
getAllTipoUsuario(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllTipoUsuario/${idtoken}/${token}`);
}

// IA06ADM - POST - INSERTAR TIPO DE USUARIO
postInsertarTipoUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarTipoUsuario` , obj);
}

// IA07ADM - POST - MODIFICAR TIPO DE USUARIO
postModificarTipoUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarTipoUsuario` , obj);
}

// GB09PRO - POST - MODIFICAR PASSWORD PARA USUARIO
postModificarPasswordUsuario(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarPasswordUsuario` , obj);
}

// IA10ADM - GET - OBTENER LISTA DE CATEGORIAS
getAllCategoria(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllCategoria/${idtoken}/${token}`);
}

// IA11ADM - POST - INSERTAR CATEGORIA
postInsertarCategoria(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarCategoria` , obj);
}

// IA12ADM - POST - MODIFICAR CATEGORIA
postModificarCategoria(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarCategoria` , obj, httpOptions);
}

// IA13ADM - POST - ELIMINAR CATEGORIA
postEliminarCategoria(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarCategoria` , obj);
}

// IA14ADM - GET - OBTENER LISTA DE CONTACTOS
getAllContacto(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllContacto/${idtoken}/${token}`);
}

// IA15ADM - POST - INSERTAR CONTACTO
postInsertarContacto(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarContacto` , obj);
}

// IA16ADM - POST - MODIFICAR CONTACTO
postModificarContacto(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarContacto` , obj);
}

// IA17ADM - POST - ELIMINAR CONTACTO
postEliminarContacto(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarContacto` , obj);
}

// IA18ADM - GET - OBTENER LISTA DE NOTICIAS
getAllNoticia(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllNoticia/${idtoken}/${token}`);
}

// IA19ADM - POST - INSERTAR NOTICIA
postInsertarNoticia(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarNoticia` , obj);
}

// IA20ADM - POST - MODIFICAR NOTICIA
postModificarNoticia(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarNoticia` , obj);
}

// IA21ADM - POST - ELIMINAR NOTICIA
postEliminarNoticia(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarNoticia` , obj);
}

// IA22ADM - GET - OBTENER LISTA DE PRODUCTOS
getAllProducto(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllProducto/${idtoken}/${token}`);
}

// IA23ADM - POST - INSERTAR PRODUCTO
postInsertarProducto(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarProducto` , obj);
}

// IA24ADM - POST - MODIFICAR PRODUCTO
postModificarProducto(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarProducto` , obj);
}

// IA25ADM - POST - ELIMINAR PRODUCTO
postEliminarProducto(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarProducto` , obj);
}


// IA30ADM - GET - OBTENER LISTA DE SUCURSALES
getAllSucursal(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getAllSucursal/${idtoken}/${token}`);
}

// IA31ADM - POST - INSERTAR SUCURSAL
postInsertarSucursal(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarSucursal` , obj);
}

// IA32ADM - POST - MODIFICAR SUCURSAL
postModificarSucursal(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarSucursal` , obj);
}

// IA33ADM - POST - ELIMINAR SUCURSAL
postEliminarSucursal(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarSucursal` , obj);
}

// IA34ADM - GET - OBTENER LISTA DE CATEGORIAS
getListaCategoria(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaCategoria/${idtoken}/${token}`);
}

// IA35ADM - GET - OBTENER LISTA DE UNIDADES DE MEDIDA
getListaUnidadMedida(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaUnidadMedida/${idtoken}/${token}`);
}

// IA36ADM - GET - OBTENER LISTA DE CONTACTOS
getListaContacto(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaContacto/${idtoken}/${token}`);
}



// IA40ADM - GET - OBTENER DETALLE DE PEDIDO
getDetallePedido(idPedido: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getDetallePedido/${idPedido}/${idtoken}/${token}`);
}

// IA41ADM - POST - MODIFICAR ESTADO PEDIDO
postModificarEstadoPedido(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarEstadoPedido` , obj);
}

// IA42ADM - GET - OBTENER LISTA DE PÁGINAS
getListaPaginas(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaPaginas/${idtoken}/${token}`);
}

// IA43ADM - GET - OBTENER LISTA DE TIPOS DE USUARIO Y PERMISOS
getTipoUsuarioPermisos(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getTipoUsuarioPermisos/${idtoken}/${token}`);
}


// IA54ADM - GET - OBTENER CANTIDADES PARA CARDS
getCantidades(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getCantidades/${idtoken}/${token}`);
}

// IA55ADM - GET - OBTENER LISTA DE CLIENTES REGISTRADOS RECIENTEMENTE
getLastClientes(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getLastClientes/${idtoken}/${token}`);
}


// IA57ADM - GET - BUSCAR CLIENTES POR NOMBRE
getBuscarCliente(nombre: any, idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getBuscarCliente/${nombre}/${idtoken}/${token}`);
}

// IA58ADM - GET - LISTA DE CLIENTES SIN CODIGO
getListaClienteSinCodigo(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaClienteSinCodigo/${idtoken}/${token}`);
}

// IA59ADM - GET - OBTENER LISTA DE CONTACTOS PARA REASIGNACION
getListaContactos(idtoken: any, token: any) {
  return this.http.get(`${this.API_Uri}/getListaContactos/${idtoken}/${token}`);
}


////////////////////////////////////////////////////////

getAllpromocion() {
  return this.http.get(this.API_Uri + `/getAllpromocion`);
}

getOnepromocion(id: any) {
 return this.http.get(`${this.API_Uri}/getOnepromocion/${id}`);
}

postInsertarpromocion(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarpromocion` , obj);
}

postModificarpromocion(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarpromocion` , obj);
}

postEliminarpromocion(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarpromocion` , obj);
}



////////////////////////////////////////////////////////
  getAllpublicidad() {
    return this.http.get(`${this.API_Uri}/getAllpublicidad`);
    }
  getOnepublicidad(id: any) {
    return this.http.get(`${this.API_Uri}/getOnepublicidad/${id}`);
  }
  postInsertarpublicidad(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarpublicidad` , obj);
  }
  postModificarpublicidad(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarpublicidad` , obj);
  }
  postEliminarpublicidad(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarpublicidad` , obj);
  }
///////////////////////////////////////////////////

getAllcalificacion() {
  return this.http.get(`${this.API_Uri}/getAllcalificacion`);
}

getOnecalificacion(id: any) {
 return this.http.get(`${this.API_Uri}/getOnecalificacion/${id}`);
}

postInsertarcalificacion(obj: any) {
  return this.http.post(`${this.API_Uri}/postInsertarcalificacion` , obj);
}

postModificarcalificacion(obj: any) {
  return this.http.post(`${this.API_Uri}/postModificarcalificacion` , obj);
}

postEliminarcalificacion(obj: any) {
  return this.http.post(`${this.API_Uri}/postEliminarcalificacion` , obj);
}
  // makeToast(type:NbComponentStatus, title:string, body:string) {
  //   this.showToast(type, title, body);
  // }

  //////
  // malezas
  /////
getAllmaleza(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllmaleza/${idtoken}/${token}`);
}

postInsertarmaleza(obj:any) {
    return this.http.post(`${this.API_Uri}/postInsertarmaleza` , obj);
}

postModificarmaleza(obj:any) {
    return this.http.post(`${this.API_Uri}/postModificarmaleza` , obj);
}

postModificarEstadomaleza(obj:any) {
    return this.http.post(`${this.API_Uri}/postModificarEstadomaleza` , obj);
}

postEliminarmaleza(obj:any) {
    return this.http.post(`${this.API_Uri}/postEliminarmaleza` , obj);
}

  //////
  //  cultivos
  /////

  getAllcultivos(idtoken: any, token: any) {
      return this.http.get(`${this.API_Uri}/getAllcultivos/${idtoken}/${token}`);
  }

  postInsertarcultivos(obj:any) {
      return this.http.post(`${this.API_Uri}/postInsertarcultivos` , obj);
  }

  postModificarcultivos(obj:any) {
      return this.http.post(`${this.API_Uri}/postModificarcultivos` , obj);
  }

  postModificarEstadocultivos(obj:any) {
      return this.http.post(`${this.API_Uri}/postModificarEstadocultivos` , obj);
  }

  postEliminarcultivos(obj:any) {
      return this.http.post(`${this.API_Uri}/postEliminarcultivos` , obj);
  }
  
  //////
  // posicionamiento
  /////

  getAllposicionamiento(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllposicionamiento/${idtoken}/${token}`);
  }

  postInsertarposicionamiento(obj:any) {
      return this.http.post(`${this.API_Uri}/postInsertarposicionamiento` , obj);
  }

  postModificarposicionamiento(obj:any) {
      return this.http.post(`${this.API_Uri}/postModificarposicionamiento` , obj);
  }

  postModificarEstadoposicionamiento(obj:any) {
      return this.http.post(`${this.API_Uri}/postModificarEstadoposicionamiento` , obj);
  }

  postEliminarposicionamiento(obj:any) {
      return this.http.post(`${this.API_Uri}/postEliminarposicionamiento` , obj);
  }


  showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 8000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
    const titleContent = title ? `${title}` : '';

    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
